import React from "react"
import { Nullable } from "../../common/nullable"
import { useLocalization } from "../i18n/kind"
import "./markdown.scss"
import * as styles from "./markdown.module.scss"

export type MarkdownProps = {
    html: Nullable<string>
    tableOfContents?: Nullable<string>
}

export function Markdown({ html, tableOfContents }: MarkdownProps) {
    if (!html) {
        return null
    }

    return (
        <>
            {!tableOfContents ? null : (
                <section className={styles.TOC}>
                    <h1>{useLocalization().TOC}</h1>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: tableOfContents,
                        }}
                    />
                </section>
            )}
            <div
                className={styles.Markdown}
                dangerouslySetInnerHTML={{
                    __html: html,
                }}
            />
        </>
    )
}

export type MarkdownExcerptProps = {
    excerpt: string | null | undefined
}

export function MarkdownExcerpt({ excerpt }: MarkdownExcerptProps) {
    if (!excerpt) {
        return null
    }

    return (
        <div
            className={styles.Markdown}
            dangerouslySetInnerHTML={{
                __html: excerpt,
            }}
        />
    )
}
