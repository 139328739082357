import React from "react"
import { HeadLayout, Layout } from "../views/layout"

export type SinglePageHeadLayoutProps = {
    isHome: boolean
    path: string
    title: string
    description: string
    json: unknown
}

export function SinglePageHeadLayout({
    isHome,
    path,
    title,
    description,
    json,
}: SinglePageHeadLayoutProps) {
    return (
        <HeadLayout
            title={title}
            description={description}
            path={path}
            isHome={isHome}
            json={json}
        />
    )
}

export type SinglePageLayoutProps = {
    isHome: boolean
}

export function SinglePageLayout({
    isHome,
    children,
}: React.PropsWithChildren<SinglePageLayoutProps>) {
    return <Layout isHome={isHome}>{children}</Layout>
}
