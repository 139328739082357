import { Kind } from "../../common/kind"
import { getListPath } from "../../common/path"
import { useSiteURL } from "../base/site"
import { localizeList } from "../i18n/kind"

export function jsonBreadcrumbList({
    items,
}: {
    items: { title: string; path: string }[]
}) {
    return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: items.map(({ title, path }, i) => ({
            "@type": "ListItem",
            position: i + 1,
            name: title,
            item: useSiteURL(path),
        })),
    }
}

export function jsonBreadcrumbListForSinglePage({
    kind,
    title,
    path,
}: {
    kind: Kind
    title: string
    path: string
}) {
    return jsonBreadcrumbList({
        items: [
            {
                title: localizeList(kind),
                path: getListPath({ kind }),
            },
            {
                title,
                path,
            },
        ],
    })
}
