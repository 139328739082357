import { useSite } from "../base/site"

export function jsonAuthor() {
    const site = useSite()

    return {
        "@type": "Person",
        name: site?.author?.name,
        sameAs: site?.author?.site,
        url: site?.author?.site,
    }
}
