import { ArticleSinglePageQuery } from "../../../types/gatsby-graphql.generated"
import { useSiteURL } from "../../base/site"
import { jsonAuthor } from "../author"
import { jsonThumbnails } from "../image"

export function jsonArticle({
    path,
    title,
    publishedAt,
    updatedAt,
    parentSeries,
}: NonNullable<ArticleSinglePageQuery["markdownRemark"]>) {
    const url = useSiteURL(path)

    return {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": url,
            url,
        },
        image: jsonThumbnails(),
        datePublished: publishedAt,
        dateModified: updatedAt,
        headline: title,
        author: jsonAuthor(),
        url,
        ...(!parentSeries || !parentSeries.length
            ? {}
            : {
                  isPartOf: parentSeries.map(series => useSiteURL(series.path)),
              }),
    }
}
