import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { ArticleSinglePageQuery } from "../../../types/gatsby-graphql.generated"
import { compareByDate } from "../../base/date"
import { jsonBreadcrumbListForSinglePage } from "../../json/breadcrumb"
import { jsonArticle } from "../../json/content/article"
import { SinglePageHeadLayout, SinglePageLayout } from "../../layouts/single"
import {
    ContentHeader,
    InfoGroup,
    InfoWith,
    KindLabel,
    LabelGroup,
    OthersInTopicSection,
    SeriesLabel,
    OthersInSeriesSection,
} from "../../views/content"
import { Markdown } from "../../views/markdown"

export function Head({ data }: PageProps<ArticleSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={false}
            path={path}
            title={title}
            description={description}
            json={[
                jsonBreadcrumbListForSinglePage({
                    kind: Kind.Article,
                    title,
                    path,
                }),
                jsonArticle(markdownRemark),
            ]}
        />
    )
}

export default function ArticleSinglePage({
    data,
}: PageProps<ArticleSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { contentId, title, parentSeries, topics, html, tableOfContents } =
        markdownRemark

    let predicate: (item: { contentId: string }) => boolean

    if (parentSeries) {
        const set = new Set<string>()

        for (const series of parentSeries) {
            if (series.items) {
                for (const item of series.items) {
                    set.add(item.contentId)
                }
            }
        }

        set.add(contentId)

        predicate = item => !set.has(item.contentId)
    } else {
        predicate = item => item.contentId !== contentId
    }

    return (
        <SinglePageLayout isHome={false}>
            <ContentHeader>
                <LabelGroup>
                    <KindLabel kind={Kind.Article} />
                    {parentSeries?.map(series => {
                        if (!series.items || series.items.length <= 0) {
                            return
                        }

                        const index = series.items.findIndex(
                            item => item.contentId === contentId
                        )

                        if (index < 0) {
                            return
                        }

                        return (
                            <SeriesLabel
                                key={series.path}
                                path={series.path}
                                title={series.title}
                                index={index}
                            />
                        )
                    })}
                </LabelGroup>
                <h1>{title}</h1>
                <InfoGroup>
                    <InfoWith {...markdownRemark} />
                </InfoGroup>
            </ContentHeader>
            <Markdown html={html} tableOfContents={tableOfContents} />
            <OthersInSeriesSection
                parentSeries={parentSeries?.sort(compareByDate)}
                select={item => item.contentId === contentId}
            />
            <OthersInTopicSection
                kind={Kind.Article}
                topics={topics}
                extract={topic => {
                    return topic.articlesInTopic
                        ?.filter(predicate)
                        .sort(compareByDate)
                }}
            />
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query ArticleSinglePage($id: String!) {
        markdownRemark(
            frontmatter: { id: { eq: $id } }
            published: { eq: true }
        ) {
            contentId
            path
            title
            description
            updatedAt
            publishedAt
            tableOfContents
            html
            topics {
                path
                title
                articlesInTopic {
                    contentId
                    path
                    title
                    updatedAt
                    updatedAtTimestamp
                    publishedAt
                    publishedAtTimestamp
                    topics {
                        path
                        title
                    }
                }
            }
            parentSeries {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
                items {
                    contentId
                    path
                    title
                    updatedAt
                    publishedAt
                    topics {
                        path
                        title
                    }
                }
            }
        }
    }
`
